var generic = generic || {};
var site = site || {};
$(document).ready(function() {
  $(window).on('window:show_afterpay_overlay', function() {
    var content = site.client.isMobile === 1 ? $('.afterpay-content-mb') : $('.afterpay-content');
    if (content.length > 0) {
      generic.overlay.launch({
        content: content.clone(true),
        includeBackground: true,
        includeCloseLink: true,
        initialHeight: 0,
        height: 0,
        width: '50%',
      });
    }
  });

  $('body').on('click', '#afterpay-learnmore', function() {
    $(window).trigger('window:show_afterpay_overlay');
    return false;
  });
});
